import CSS from "csstype";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  calcResultAverage,
  calcResultAvg,
  isDiffusionComplete,
  msToTime,
} from "../../../utils/util";

import senseiApi from "../../../api/sensei";
import { Context as SensorContext } from "../../../context/SensorContext";
import { Context as SettingsContext } from "../../../context/SettingsContext";
import { Context as LocationContext } from "../../../context/LocationContext";
import { Context as TestContext } from "../../../context/TestContext";
import { paths } from "../../../utils/const";
import { MS_BACKGROUND, MS_SETTLING } from "./constants";
import { useTranslation } from "react-i18next";
import { ModalAction } from "../../../components/BaseModal";
import { Test241, TestEACH } from "../../../utils/types";
import { findTarget } from "../../../utils/findTarget";

function getQueryString(data = {}) {
  return Object.entries(data)
    .map(
      ([key, value]: [key: any, value: any]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

export const useConnect = () => {
  const history = useHistory();
  const { t } = useTranslation();
  let { tid } = useParams<{ tid: any | undefined }>();

  const {
    state: { location, site, zone },
    getCurrentLocation,
    getCurrentSite,
    getCurrentZone,
    resetState: resetLocationState,
  } = useContext(LocationContext);
  const { state: sensorState, getMyDevices } = useContext(SensorContext);
  const { state: settings } = useContext(SettingsContext);
  const {
    state: { rapidTest: rT, tid: currentRapidTest, chartData, isLoading },
    fetchRapidTest,
    fetchChartData,
    getTests,
    updateTestNextStep,
    setCurrentRapidTest,
    resetState: resetTestState,
  } = useContext(TestContext);

  const rapidTest = useMemo(() => rT as Test241, [rT]);

  const [sensorMeasurements, setSensorMeasurements] = useState<any[]>([]);
  const [rapidTestTimeRemaining, setRapidTestTimeRemaining] = useState<
    string | undefined
  >(undefined);
  const [eachAvg, setEachAvg] = useState<number | undefined>();
  const [gavfrActual, setGavfrActual] = useState<number | undefined>();
  const [gavfrTarget, setGavfrTarget] = useState<number>(0);
  const [resultAvg, setResultAvg] = useState<number | undefined>();
  const [gaugeFillStyles1, setGaugeFillStyles1] = useState({});
  const [gaugeFillStyles3, setGaugeFillStyles3] = useState({});
  const [showStopModal, setShowStopModal] = useState(false);
  const [showDiffuseModal, setShowDiffuseModal] = useState<
    undefined | "start" | "stop" | "hide"
  >(undefined);

  const returnHome = useCallback(() => {
    resetLocationState();
    resetTestState();
    history.push(paths.home);
  }, [history]);

  // rename this
  const startRapidTest = useCallback(async () => {
    if (rapidTest.status) {
      if ("Notification" in window && Notification.permission !== "granted")
        Notification.requestPermission();
      if (
        rapidTest.status === "background" ||
        rapidTest.status === "diffusion" ||
        rapidTest.status === "settling"
        // !testIsLoading
      ) {
        // update loading status
        await updateTestNextStep(tid);
        await fetchRapidTest(tid, "241");
      } else if (rapidTest.status === "success") {
        returnHome();
      }
    }
  }, [rapidTest.status, updateTestNextStep, tid, fetchRapidTest, returnHome]);

  const stopRapidTest = useCallback(async () => {
    let data = {
      stop: true,
    };
    await senseiApi.put(`/tests/${tid}`, getQueryString(data), {
      withCredentials: true,
    });
    setShowDiffuseModal(undefined);
    setShowStopModal(false);
    history.push(paths.home);
    getMyDevices();
    getTests("241");
    resetLocationState();
    resetTestState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, showStopModal, tid]);

  const restartRapidTest = useCallback(async () => {
    if (rapidTest?.status === "error") {
      let data = {
        did0: rapidTest.did0,
        did1: rapidTest.did1,
        did2: rapidTest.did2,
        did3: rapidTest.did3,
        zone: rapidTest.zone,
        email: rapidTest.email,
        hvac: rapidTest.hvac,
        configuration: rapidTest.configuration,
        notes: rapidTest.notes,
      };
      let res = await senseiApi.post("/tests", getQueryString(data), {
        withCredentials: true,
        maxRedirects: 0,
      });
      setShowDiffuseModal(undefined);
      setShowStopModal(false);
      history.push(`/test/${res?.data?.id}`);
      setCurrentRapidTest(res?.data?.id);
      getTests("241");
    }
  }, [rapidTest, history, getTests, setCurrentRapidTest]);

  useEffect(() => {
    if (rapidTest?.zone && zone?.zid !== rapidTest?.zone) {
      getCurrentZone(rapidTest.zone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rapidTest, zone?.zid]);

  // 1s interval: tick rapidTestTimeRemaining
  useEffect(() => {
    let intervalFunction = setInterval(async () => {
      if (rapidTest.start) {
        // Use 20 minutes plus 14 seconds to allow for delay in API response
        let msRemaining = 0; // 1800000 // 25000
        switch (rapidTest.status) {
          case "background":
            msRemaining =
              new Date(rapidTest.start).getTime() +
              MS_BACKGROUND -
              new Date().getTime();
            break;
          case "diffusion":
            msRemaining =
              new Date().getTime() -
              new Date(rapidTest?.background_end ?? "").getTime();
            break;
          case "settling":
            msRemaining =
              new Date(rapidTest.diffusion_end ?? "").getTime() +
              MS_SETTLING -
              new Date().getTime();
            break;
          case "measuring":
            msRemaining =
              new Date().getTime() -
              new Date(rapidTest?.settling_end ?? "").getTime();
            break;
          default:
            break;
        }

        if (msRemaining > 0) {
          setRapidTestTimeRemaining(msToTime(msRemaining));
        } else {
          setRapidTestTimeRemaining("00:00");
          if (
            rapidTest.oneclick === 1 &&
            (rapidTest.status === "background" ||
              rapidTest.status === "diffusion" ||
              rapidTest.status === "settling" ||
              rapidTest.status === "measuring")
          ) {
            await fetchRapidTest(tid, "241");
          } else {
            clearInterval(intervalFunction);
          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalFunction);
    };
  }, [
    tid,
    rapidTestTimeRemaining,
    rapidTest.status,
    rapidTest?.background_end,
    rapidTest.diffusion_end,
    rapidTest?.settling_end,
    rapidTest.start,
  ]);

  // 15sec interal: fetch eACH test result && chart data
  useEffect(() => {
    let isMounted = true;

    const tick = async () => {
      if (isMounted) {
        if (tid > 0) {
          if (currentRapidTest !== tid) {
            setCurrentRapidTest(tid);
            fetchRapidTest(tid, "241");
          }
          if (
            currentRapidTest === tid &&
            rapidTest.status !== "success" &&
            rapidTest.status !== "error"
          ) {
            fetchRapidTest(tid, "241");
          }
          if (rapidTest.status !== "success" && sensorState.devices[0]?.did) {
            fetchChartData(sensorState.devices[0].did);
          }
        } else returnHome();
      }
    };
    let tickInt = setInterval(tick, 15 * 1000);

    tick();

    return () => {
      isMounted = false;
      clearInterval(tickInt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid, sensorState.devices, rapidTest.status]);

  useEffect(() => {
    const fetchSite = async () => {
      if (zone?.site && (!site || site.sid !== zone.site))
        getCurrentSite(zone?.site);
    };
    const fetchLocation = async () => {
      if (site?.location && (!location || location.lid !== site?.location))
        getCurrentLocation(site?.location);
    };
    fetchSite();
    fetchLocation();
  }, [location, site, zone?.site]);

  // set display values
  useEffect(() => {
    let volume =
      zone?.volume && zone.volume > 0
        ? zone.volume
        : (zone?.length ?? 0) * (zone?.width ?? 0) * (zone?.height ?? 0);
    let occupancy = rapidTest.occupancy;
    if (rapidTest && occupancy) {
      // rename these values to be more clear && make into Memo values
      setEachAvg(rapidTest.each);
      if (
        rapidTest.status === "success" &&
        (rapidTest.result0 >= 0 ||
          rapidTest.result1 >= 0 ||
          rapidTest.result2 >= 0 ||
          rapidTest.result3 >= 0)
      ) {
        let results = [
          rapidTest.result0,
          rapidTest.result1,
          rapidTest.result2,
          rapidTest.result3,
        ].filter((val) => val >= 0);
        setGavfrActual(
          parseFloat(
            (
              results.reduce((a, b) => a + b) /
              results.length /
              rapidTest.occupancy
            ).toFixed(1)
          )
        );
      }
      setGavfrTarget(findTarget(zone?.type ?? ""));
      setResultAvg(parseFloat(rapidTest.each.toFixed(1)));
      if (rapidTest.status === "success") {
        let low = Math.min(
          ...[
            rapidTest.result0,
            rapidTest.result1,
            rapidTest.result2,
            rapidTest.result3,
          ].filter((a) => a > 0)
        );
        let lowIndex = [
          rapidTest.result0,
          rapidTest.result1,
          rapidTest.result2,
          rapidTest.result3,
        ].findIndex((value) => value === low);

        setSensorMeasurements(
          [
            {
              name:
                sensorState.allDevices?.find(
                  (device) => device.did === rapidTest?.did0
                )?.iotid ?? "Device not found",
              value:
                rapidTest.result0 > 0
                  ? (rapidTest.result0 / rapidTest.occupancy).toFixed(1)
                  : "N/A",
              isLowest: lowIndex === 0,
            },
            {
              name:
                sensorState.allDevices?.find(
                  (device) => device.did === rapidTest?.did1
                )?.iotid ?? "Device not found",
              value:
                rapidTest.result1 > 0
                  ? (rapidTest.result1 / rapidTest.occupancy).toFixed(1)
                  : "N/A",
              isLowest: lowIndex === 1,
            },
            {
              name:
                sensorState.allDevices?.find(
                  (device) => device.did === rapidTest?.did2
                )?.iotid ?? "Device not found",
              value:
                rapidTest.result2 > 0
                  ? (rapidTest.result2 / rapidTest.occupancy).toFixed(1)
                  : "N/A",
              isLowest: lowIndex === 2,
            },
            {
              name:
                sensorState.allDevices?.find(
                  (device) => device.did === rapidTest?.did3
                )?.iotid ?? "Device not found",
              value:
                rapidTest.result3 > 0
                  ? (rapidTest.result3 / rapidTest.occupancy).toFixed(1)
                  : "N/A",
              isLowest: lowIndex === 3,
            },
          ].filter((a) => a.value !== "N/A")
        );
      }
    }
  }, [rapidTest, zone, sensorState, rapidTest.status, sensorState.allDevices]);

  useEffect(() => {
    // setRapidTestStartTime(undefined);
    setRapidTestTimeRemaining(undefined);
  }, [tid]);

  // gauge styles
  // clean up and move somewhere else
  useEffect(() => {
    if (!!rapidTest.diffusion_end) {
      let msRemaining =
        new Date(rapidTest.diffusion_end).getTime() +
        MS_SETTLING -
        new Date().getTime();
      msRemaining = msRemaining ? msRemaining : 0;
      const gaugeWidth = ((MS_SETTLING - msRemaining) / MS_SETTLING) * 74;
      const gaugeFill3: CSS.Properties = {
        width: `${
          gaugeWidth >= 5 ? (gaugeWidth <= 74 ? gaugeWidth : 74) : 5
        }px`,
      };
      setGaugeFillStyles3(gaugeFill3);
    }
    if (!!rapidTest.start) {
      let msRemaining =
        new Date(rapidTest.start).getTime() +
        MS_BACKGROUND -
        new Date().getTime();
      msRemaining = msRemaining ? msRemaining : 0;
      const gaugeWidth = ((MS_BACKGROUND - msRemaining) / MS_BACKGROUND) * 74;
      const gaugeFill1: CSS.Properties = {
        width: `${
          gaugeWidth >= 5 ? (gaugeWidth <= 74 ? gaugeWidth : 74) : 5
        }px`,
      };
      setGaugeFillStyles1(gaugeFill1);
      return () => {
        setGaugeFillStyles1({});
        setGaugeFillStyles3({});
      };
    }
  }, [tid, rapidTest.start, rapidTestTimeRemaining, rapidTest.diffusion_end]);

  // simplify
  const disabledStart = useMemo(() => {
    if (rapidTest.status === "success" || rapidTest.status === "error")
      return false;
    if (rapidTest.oneclick === 1) return true;
    if (isLoading) return true;
    const devicesOnline =
      sensorState.devices?.filter((d: any) => d.online).length > 0;
    if (rapidTest.status === "diffusion" && isDiffusionComplete(rapidTest))
      return false;
    return (
      rapidTestTimeRemaining !== "00:00" ||
      (rapidTest.status === "" && !devicesOnline) ||
      rapidTest.status === "settling" ||
      rapidTest.status === "measuring" ||
      rapidTest.status === "diffusion"
    );
  }, [isLoading, rapidTestTimeRemaining, rapidTest, sensorState.devices]);

  // cleanup because this is gross to look at
  const startButtonText = useMemo(() => {
    if (rapidTest.oneclick === 1) {
      switch (rapidTest.status) {
        case "background":
          return (
            t("measuring-background") +
            ".".repeat(((new Date().getTime() / 1000) % 3) + 1)
          );
        case "diffusion":
        case "measuring":
          return (
            t("measuring") + ".".repeat(((new Date().getTime() / 1000) % 3) + 1)
          );
        case "settling":
          return (
            t("settling") + ".".repeat(((new Date().getTime() / 1000) % 3) + 1)
          );
        case "complete":
          return t("button.returnHome");
        default:
          break;
      }
    }
    if (rapidTest.status === "diffusion" && isDiffusionComplete(rapidTest))
      return t("button.stop.diffusion");
    if (rapidTestTimeRemaining === "00:00") {
      switch (rapidTest.status) {
        case "background":
          return t("button.start.diffusion");
        // case "diffusion":
        //   return t("button.stop.diffusion");
        case "settling":
          return t("settling");
        case "measuring":
          return t("measuring") + "...";
        case "complete":
          return t("button.returnHome");
        default:
          break;
      }
    }
    return rapidTest.status === "background" &&
      rapidTestTimeRemaining !== "00:00"
      ? t("measuring-background") +
          ".".repeat(
            (-parseInt((rapidTestTimeRemaining || "").slice(-2)) % 4) + 3
          )
      : rapidTest.status === "diffusion" && rapidTestTimeRemaining !== "00:00"
      ? t("measuring") +
        ".".repeat(
          (-parseInt((rapidTestTimeRemaining || "").slice(-2)) % 4) + 3
        )
      : rapidTest.status === "settling" && rapidTestTimeRemaining !== "00:00"
      ? `${
          t("settling") +
          ".".repeat(
            (-parseInt((rapidTestTimeRemaining || "").slice(-2)) % 4) + 3
          )
        }`
      : rapidTest.status === "measuring" && rapidTestTimeRemaining !== "00:00"
      ? `${
          t("measuring") +
          ".".repeat(
            (-parseInt((rapidTestTimeRemaining || "").slice(-2)) % 4) + 3
          )
        }`
      : rapidTest.status === "success" || rapidTest.status === "error"
      ? "submit-and-return-home"
      : "";
  }, [rapidTestTimeRemaining, rapidTest, t]);

  const msRemaining = useMemo(() => {
    let msRemaining = undefined;
    switch (rapidTest.status) {
      case "background":
        msRemaining =
          new Date(rapidTest.start).getTime() +
          MS_BACKGROUND -
          new Date().getTime();
        break;
      case "diffusion":
        msRemaining =
          new Date().getTime() -
          new Date(rapidTest?.background_end ?? "").getTime();
        break;
      case "settling":
        msRemaining =
          new Date(rapidTest.diffusion_end ?? "").getTime() +
          MS_SETTLING -
          new Date().getTime();
        break;
      case "measuring":
        msRemaining =
          new Date().getTime() -
          new Date(rapidTest?.settling_end ?? "").getTime();
        break;
      default:
        break;
    }
    return msRemaining;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rapidTestTimeRemaining]);

  useEffect(() => {
    if (rapidTest.oneclick === 1) {
      setShowDiffuseModal("hide");
      return;
    }
    if (
      showDiffuseModal === "hide" &&
      rapidTest.status === "diffusion" &&
      !isDiffusionComplete(rapidTest)
    ) {
      setShowDiffuseModal(undefined);
      return;
    }
    if (showDiffuseModal === "hide" || msRemaining === undefined) return;
    if (rapidTest.status === "background" && msRemaining < 60000) {
      setShowDiffuseModal("start");
    } else if (
      rapidTest.status === "diffusion" &&
      isDiffusionComplete(rapidTest)
    ) {
      setShowDiffuseModal("stop");
    }
  }, [rapidTestTimeRemaining, rapidTest, showDiffuseModal, msRemaining]);

  const testResult = useMemo(() => {
    if (rapidTest.status !== "success") return "";
    if (!gavfrActual || !gavfrTarget) return "";
    if (gavfrActual >= gavfrTarget) return "success";
    else if (gavfrActual < gavfrTarget) return "fail";
    else return "";
  }, [gavfrActual, gavfrTarget, rapidTest.status]);

  const passesForOccupants = useMemo(() => {
    const actual = calcResultAvg(rapidTest);
    const target = rapidTest.target;
    const peak = rapidTest.occupancy ?? zone?.peak ?? 0;
    return Math.floor(actual / (target / peak));
  }, [rapidTest, zone?.peak]);

  const [notes, setNotes] = useState(rapidTest?.notes);
  const [notesIsLoading, setNotesIsLoading] = useState(false);

  const handleNotesChange = (event: any) => {
    if (event.target.value.length < 256) setNotes(event.target.value);
  };

  const submitNotes = useCallback(async () => {
    if (notes !== rapidTest.notes) {
      setNotesIsLoading(true);
      let data = {
        notes: notes,
      };
      await senseiApi.put(`/tests/${tid}`, getQueryString(data), {
        withCredentials: true,
        maxRedirects: 0,
      });
      await fetchRapidTest(tid, "241");
      setNotesIsLoading(false);
    }
  }, [fetchRapidTest, notes, rapidTest.notes, tid]);

  useEffect(() => {
    if (rapidTest?.notes !== notes) {
      setNotes(rapidTest?.notes);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rapidTest?.notes]);

  const notesDisabledSubmit = useMemo(() => {
    return !!(notes === rapidTest.notes || notesIsLoading);
  }, [notes, notesIsLoading, rapidTest.notes]);

  const errorModalActions = useMemo<
    ModalAction | ModalAction[] | undefined
  >(() => {
    if (rapidTest.status !== "error") return undefined;
    if (rapidTest.errorcode === 112 || rapidTest.errorcode === 113)
      return [
        {
          onClick: restartRapidTest,
          type: "default",
          text: t("modal.button.reRunTest"),
        },
        {
          onClick: returnHome,
          type: "submit",
          text: t("modal.button.returnHome"),
        },
      ];
    return {
      onClick: returnHome,
      type: "danger",
      text: t("modal.button.returnHome"),
    };
  }, [rapidTest.errorcode, rapidTest.status, restartRapidTest, returnHome, t]);

  const cfmDiff = useMemo(() => {
    let peak = rapidTest.occupancy ?? zone?.peak;
    if (!!gavfrActual && !!gavfrTarget && peak)
      return Math.floor(Math.abs(gavfrActual * peak - gavfrTarget * peak));
  }, [gavfrActual, gavfrTarget]);

  const [macsoErrorOpen, setMacsoErrorOpen] = useState(
    rapidTest.errorcode === 500
  );

  useEffect(() => {
    if (rapidTest.errorcode === 500) {
      setMacsoErrorOpen(true);
    }
  }, [rapidTest.errorcode]);

  const kitDevices = useMemo(
    () =>
      sensorState.allDevices
        .filter((item) =>
          [
            rapidTest?.did0,
            rapidTest?.did1,
            rapidTest?.did2,
            rapidTest?.did3,
          ].includes(item.did)
        )
        .sort((a, b) => a.did - b.did),
    [sensorState.allDevices, rapidTest]
  );

  return {
    chartData,
    allDevices: sensorState.allDevices,
    devices: sensorState.devices,
    kitDevices: kitDevices,
    eachAvg,
    gavfrActual,
    gavfrTarget,
    resultAvg,
    cfmDiff,
    gaugeFillStyles1,
    gaugeFillStyles3,
    location,
    site,
    zone,
    rapidTestTimeRemaining,
    rapidTest,
    sensorState,
    sensorMeasurements,
    disabledStart,
    startButtonText,
    startRapidTest,
    stopRapidTest,
    toggleStopModal: () => setShowStopModal(!showStopModal),
    showStopModal,
    showDiffuseModal,
    errorModalActions,
    closeDiffuserModal: () => setShowDiffuseModal("hide"),
    restartRapidTest,
    returnHome,
    testResult,
    passesForOccupants,
    hideEach: settings.hideEach,
    notes,
    handleNotesChange,
    submitNotes,
    notesDisabledSubmit,
    macsoErrorOpen,
    toggleMacsoErrorOpen: () => setMacsoErrorOpen(!macsoErrorOpen),
  };
};

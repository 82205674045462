import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { calcResultAvg } from "../../../utils/util";

import senseiApi from "../../../api/sensei";
import { Context as SensorContext } from "../../../context/SensorContext";
import { Context as LocationContext } from "../../../context/LocationContext";
import { Context as TestContext } from "../../../context/TestContext";
import { paths } from "../../../utils/const";
import { Conditions, TestEACH } from "../../../utils/types";
import { useTranslation } from "react-i18next";
import { ModalAction } from "../../../components/BaseModal";

function getQueryString(data = {}) {
  return Object.entries(data)
    .map(
      ([key, value]: [key: any, value: any]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

export const useConnect = () => {
  const history = useHistory();
  const { t } = useTranslation();
  let { tid } = useParams<{ tid: any | undefined }>();

  const {
    state: { location, site, zone, currentLocation, currentRapidTest },
    getCurrentLocation,
    getCurrentSite,
    getCurrentZone,
    setCurrentRapidTest,
    resetState: resetLocationState,
  } = useContext(LocationContext);
  const { state: sensorState, getMyDevices } = useContext(SensorContext);
  const {
    state: {
      chartData,
      conditionsInfo,
      rapidTest,
      rapidTestSensorData: sensorData,
    },
    fetchChartData,
    fetchRapidTest,
    getTests,
    setConditionsInfo,
    resetState: resetTestState,
  } = useContext(TestContext);

  const [testStatus, setTestStatus] = useState<string>("loading");
  const [buttonText, setButtonText] = useState<string>();

  const eACH = useMemo(() => {
    if (rapidTest.status === "running") {
      return calcResultAvg(rapidTest);
    } else {
      return Math.max(
        ...[rapidTest.each0, rapidTest.each1, rapidTest.each2, rapidTest.each3]
      );
    }
  }, [rapidTest]);

  const zoneVolume = useMemo(() => {
    let height = zone?.height ?? 0;
    let width = zone?.width ?? 0;
    let length = zone?.length ?? 0;
    return zone?.volume && zone.volume > 0
      ? zone.volume
      : height * width * length;
  }, [zone]);

  useEffect(() => {
    if (rapidTest?.zone && zone?.zid !== rapidTest?.zone)
      getCurrentZone(rapidTest.zone);
    if (zone?.site && (!site || site.sid !== zone.site))
      getCurrentSite(zone?.site);
    if (site?.location && (!location || location.lid !== site?.location))
      getCurrentLocation(site?.location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rapidTest, zone?.zid, location, site, zone?.site]);

  const startRapidTest = useCallback(async () => {
    if (!conditionsInfo.email || conditionsInfo.hvac < 0) {
      setCurrentRapidTest(-1);
      history.push(paths.conditionsInfo);
      return;
    }
    let onlineSensors = sensorState.devices?.filter((d: any) => d.online);
    if (onlineSensors.length > 0) {
      let data = {
        location: (rapidTest as TestEACH).zone,
        did0: (rapidTest as TestEACH).did0 ?? -1,
        did1: (rapidTest as TestEACH).did1 ?? -1,
        did2: (rapidTest as TestEACH).did2 ?? -1,
        did3: (rapidTest as TestEACH).did3 ?? -1,
        diffuser: rapidTest?.diffuser,
        email: (rapidTest as TestEACH).email,
        hvac: (rapidTest as TestEACH).hvac,
        interventions: (rapidTest as TestEACH).interventions,
        cadr: (rapidTest as TestEACH).cadr,
        configuration: (rapidTest as TestEACH).configuration,
        notes: (rapidTest as TestEACH).notes,
        oneclick: rapidTest?.oneclick,
        bkgd_time: (rapidTest as TestEACH).bkgd_time,
        diff_time: (rapidTest as TestEACH).diff_time,
        sett_time: (rapidTest as TestEACH).sett_time,
        decay_time: (rapidTest as TestEACH).decay_time,
      };
      let res: any;
      res = await senseiApi.post("/start-each", getQueryString(data), {
        withCredentials: true,
        maxRedirects: 0,
      });
      setConditionsInfo({} as Conditions);
      setCurrentRapidTest(res?.data?.id);
      history.push(`/each/${res?.data?.id}`);
    }
  }, [
    conditionsInfo,
    currentLocation,
    history,
    sensorState.devices,
    setConditionsInfo,
    setCurrentRapidTest,
  ]);

  // tick rapidTestTimeRemaining
  useEffect(() => {
    const tick = async () => {
      let test = rapidTest as TestEACH;
      if (rapidTest.status === "success" || rapidTest.status === "error") {
        setButtonText(t("button.returnHome"));
        return;
      }
      if (test.start) {
        const testTime = new Date(test.start);
        const timeElasped = new Date().getTime() - testTime.getTime();
        let status;
        if (test.status === "error" || test.status === "success")
          status = test.status;
        if (test.status === "running") {
          if (timeElasped < test.bkgd_time * 1000) {
            status = "background";
          } else if (
            timeElasped <
            test.bkgd_time * 1000 + test.diff_time * 1000
          ) {
            status = "diffusion";
          } else if (
            timeElasped <
            test.bkgd_time * 1000 +
              test.diff_time * 1000 +
              test.sett_time * 1000
          ) {
            status = "settling";
          } else status = "measuring";
        } else status = "loading";
        let now = new Date().getTime();
        let text = "";
        switch (status) {
          case "background":
            text = "measuring-background";
            break;
          case "diffusion":
            text = "measuring";
            break;
          case "settling":
            text = "settling";
            break;
          case "measuring":
            text = "measuring";
            break;
          default:
            break;
        }
        setButtonText(`${t(text)}${".".repeat(((now / 1000) % 3) + 1)}`);
      }
    };
    let intervalFunction = setInterval(tick, 1000);
    return () => {
      clearInterval(intervalFunction);
    };
  }, [tid, rapidTest, zoneVolume]);

  useEffect(() => {
    if (tid > 0) {
      setCurrentRapidTest(tid);
    } else {
      setCurrentRapidTest(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch eACH test result && chart data
  useEffect(() => {
    let isMounted = true;

    const tick = async () => {
      if (isMounted) {
        if (currentRapidTest !== parseInt(tid)) {
          setCurrentRapidTest(parseInt(tid));
          fetchRapidTest(parseInt(tid), "each");
        }
        if (
          currentRapidTest === parseInt(tid) &&
          rapidTest.status === "running"
        ) {
          fetchRapidTest(parseInt(tid), "each");
          getCurrentLocation(rapidTest.location);
          getCurrentSite(rapidTest.site);
          getCurrentZone(rapidTest.zone);
          if (sensorState.devices[0]?.did)
            fetchChartData(sensorState.devices[0].did, rapidTest.start);
        }
      }
    };
    let tickInt = setInterval(tick, 15 * 1000);

    tick();

    return () => {
      isMounted = false;
      clearInterval(tickInt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid, sensorState.devices, testStatus]);

  useEffect(() => {
    const tick = () => {
      const test = rapidTest as TestEACH;
      const testTime = new Date(test.start);
      const timeElasped = new Date().getTime() - testTime.getTime();
      if (test.status === "error" || test.status === "success") {
        setTestStatus(test.status);
        return;
      }
      if (test.status === "running") {
        if (timeElasped < test.bkgd_time * 1000) {
          setTestStatus("background");
        } else if (
          timeElasped <
          test.bkgd_time * 1000 + test.diff_time * 1000
        ) {
          setTestStatus("diffusion");
        } else if (
          timeElasped <
          test.bkgd_time * 1000 + test.diff_time * 1000 + test.sett_time * 1000
        ) {
          setTestStatus("settling");
        } else setTestStatus("measuring");
        return;
      } else setTestStatus("loading");
    };

    let tickInterval = setInterval(tick, 1000);

    tick();

    return () => {
      clearInterval(tickInterval);
    };
  }, [rapidTest.status]);

  const disabledStart = useMemo(() => {
    const devicesOnline =
      sensorState.devices?.filter((d: any) => d.online).length > 0;
    return rapidTest.status === "running" || !devicesOnline;
  }, [sensorState.devices, rapidTest.status]);

  const [showStopModal, setShowStopModal] = useState(false);

  const stopRapidTest = useCallback(async () => {
    let data = {
      tid: tid,
    };
    await senseiApi.post("/stop-each", getQueryString(data), {
      withCredentials: true,
      maxRedirects: 0,
    });
    setShowStopModal(!showStopModal);
    history.push(paths.home);
    getMyDevices();
    getTests("each");
    resetLocationState();
    resetTestState();
  }, [tid, getMyDevices, resetTestState, resetLocationState]);

  const [showErrorModal, setShowErrorModal] = useState(false);

  // error modal effect
  useEffect(() => {
    if (rapidTest?.status === "error" && rapidTest?.errorcode !== 1) {
      setShowErrorModal(true);
    }
  }, [rapidTest]);

  const restartRapidTest = useCallback(async () => {
    setShowErrorModal(false);
    setConditionsInfo({
      email: rapidTest.email,
      interventions: (rapidTest as TestEACH).interventions,
      cadr: (rapidTest as TestEACH).cadr,
      hvac: rapidTest.hvac,
      configuration: rapidTest.configuration,
      notes: rapidTest.notes,
    });
    startRapidTest();
  }, [rapidTest]);

  const returnHome = () => {
    resetTestState();
    resetLocationState();
    history.push(paths.home);
  };

  const errorModalActions = useMemo<
    ModalAction | ModalAction[] | undefined
  >(() => {
    if (rapidTest.status !== "error") return undefined;
    if (rapidTest.errorcode === 112 || rapidTest.errorcode === 113)
      return [
        {
          onClick: restartRapidTest,
          type: "default",
          text: t("modal.button.reRunTest"),
        },
        {
          onClick: returnHome,
          type: "submit",
          text: t("modal.button.returnHome"),
        },
      ];
    return {
      onClick: returnHome,
      type: "danger",
      text: t("modal.button.returnHome"),
    };
  }, [rapidTest.errorcode, rapidTest.status, restartRapidTest, returnHome, t]);

  const [macsoErrorOpen, setMacsoErrorOpen] = useState(
    rapidTest.errorcode === 500
  );

  useEffect(() => {
    if (rapidTest.errorcode === 500) {
      setMacsoErrorOpen(true);
    }
  }, [rapidTest.errorcode]);

  const kitDevices = useMemo(
    () =>
      sensorState.allDevices
        .filter((item) =>
          [
            rapidTest?.did0,
            rapidTest?.did1,
            rapidTest?.did2,
            rapidTest?.did3,
          ].includes(item.did)
        )
        .sort((a, b) => a.did - b.did),
    [sensorState.allDevices, rapidTest]
  );

  return {
    currentRapidTest,
    eACH,
    chartData,
    zoneVolume,
    location,
    site,
    zone,
    rapidTest,
    testStatus,
    sensorState,
    sensorData,
    sensors: sensorState.allDevices,
    kitDevices: kitDevices,
    disabledStart,
    buttonText,
    startRapidTest,
    stopRapidTest,
    toggleStopModal: () => setShowStopModal(!showStopModal),
    showStopModal,
    showErrorModal,
    restartRapidTest,
    returnHome,
    errorModalActions,
    macsoErrorOpen,
    toggleMacsoErrorOpen: () => setMacsoErrorOpen(!macsoErrorOpen),
  };
};

import styles from "./eACH.module.scss";

import DeviceLineChart from "../../../components/DeviceLineChart";
import Modal from "../../../components/BaseModal";
import { getAqiLevel } from "../../../calculations";
import { useTranslation } from "react-i18next";
import { useConnect } from "./connect";

import SensorStatusList from "../../../components/SensorStatusList/index";
import ProgressBar from "../../../components/ProgressBar";
import { TestEACH } from "../../../utils/types";
import TestLocationInfo from "../../../components/TestLocationInfo";
import SensorMeasurements from "../../../components/SensorMeasurements";

const RapidTest = () => {
  const { t } = useTranslation();

  const {
    currentRapidTest,
    chartData,
    eACH,
    zoneVolume,
    location,
    site,
    zone,
    rapidTest,
    testStatus,
    sensorState,
    sensorData,
    sensors,
    kitDevices,
    disabledStart,
    startRapidTest,
    buttonText,
    stopRapidTest,
    toggleStopModal,
    showStopModal,
    showErrorModal,
    restartRapidTest,
    returnHome,
    errorModalActions,
    macsoErrorOpen,
    toggleMacsoErrorOpen,
  } = useConnect();

  return (
    <div className={styles.root}>
      <TestLocationInfo location={location} site={site} zone={zone} />
      <ProgressBar
        testStatus={testStatus}
        start={rapidTest.start}
        stop={rapidTest.stop}
        bkgdTime={(rapidTest as TestEACH).bkgd_time}
        diffTime={(rapidTest as TestEACH).diff_time}
        settTime={(rapidTest as TestEACH).sett_time}
        decayTime={(rapidTest as TestEACH).decay_time}
      />
      <div className={styles.buttonContainer}>
        <button
          className={styles.testActionButton}
          onClick={
            rapidTest.status === "success" || rapidTest.status === "error"
              ? returnHome
              : startRapidTest
          }
          disabled={disabledStart || !buttonText}
        >
          {!buttonText ? t("testStatus.loading") : buttonText}
        </button>
      </div>
      {rapidTest.status !== "" && (
        <div className={styles.interimResultContainer}>
          <h2 className={styles.header}>{t("interim-measurement")}</h2>
          <div className={styles.mainValueContainer}>
            <p className={styles.mainValue}>
              {!!eACH && !!zoneVolume && !!zone?.peak
                ? `${((eACH * zoneVolume) / 60 / zone?.peak).toFixed(1)}  `
                : "- "}
              <span className={styles.mainValueLabel}>
                {t("eca-label")}
                <sub>
                  {rapidTest.status === "running"
                    ? t("eoa-subscript-label")
                    : t("eoa-subscript-label-max")}
                </sub>
              </span>
            </p>
            <p className={styles.label}>{t("label.cfm-person")}</p>
          </div>
          <div className={styles.measurementsContainer}>
            <div className={styles.measurement}>
              <p className={styles.value}>
                <b>{!!eACH ? `${eACH.toFixed(1)}  ` : "- "}</b>
                {t("each-label")}
                <sub>
                  {rapidTest.status === "running"
                    ? t("avg-label")
                    : t("max-label")}
                </sub>
              </p>
              <p className={styles.label}>{t("size-label")}</p>
            </div>
            <div className={styles.measurement}>
              <p className={styles.value}>
                <b>
                  {!!eACH
                    ? `${((1 - 1 / 2.718 ** eACH) * 100).toFixed(1)} `
                    : "- "}
                </b>
                %
                <sub>
                  {rapidTest.status === "running"
                    ? t("avg-label")
                    : t("max-label")}
                </sub>
              </p>
              <p className={styles.label}>{t("reduction-rate")}</p>
            </div>
          </div>
          {rapidTest.status === "running" && (
            <DeviceLineChart
              type="PM1.0"
              key="PM1.0 Chart"
              chartData={chartData}
              range={1000}
              colorFunction={(number: number) => {
                return "#64E8DE";
              }}
              levelFunction={getAqiLevel}
              hideTooltip
            />
          )}
        </div>
      )}
      {rapidTest.status === "running" && (
        <SensorStatusList
          kitDevices={kitDevices}
          allSensors={sensors}
          testType="each"
        />
      )}
      <SensorMeasurements
        test={rapidTest as TestEACH}
        devices={sensorState.allDevices}
        sensorData={sensorData}
        peak={zone?.peak ?? 0}
        volume={zoneVolume}
      />
      {currentRapidTest !== -1 && (
        <div className={styles.sensorStatusContainer}>
          <div className={styles.onGoingTestBox}>
            <h3>{t("test-explanation-header")}</h3>
            <ul>
              <li>{t("test-explanation-part1")}</li>
              <li>{t("test-explanation-part2")}</li>
              <li>{t("test-explanation-part3")}</li>
            </ul>
          </div>
        </div>
      )}
      <>
        {rapidTest.status === "running" ? (
          <div className={styles.buttonContainer}>
            <button className={styles.stopRapidTest} onClick={toggleStopModal}>
              {t("stop")}
            </button>
          </div>
        ) : (
          <></>
        )}
        {/* Export all of these modals into sub folders using BaseModal to extend, cleaning up this file */}
        {/* <Modal
          title={
            showDiffuseModal === "start"
              ? t("modal.nebulize.start.title")
              : t("modal.nebulize.stop.title")
          }
          children={
            showDiffuseModal === "start" ? (
              <>
                <p className={styles.typographyMessage}>
                  {t("modal.nebulize.start.message", {
                    time: rapidTestTimeRemaining,
                  })}
                </p>
                <p className={styles.typographyMessageHint}>
                  {t("modal.nebulize.start.description")}
                </p>
              </>
            ) : (
              <p className={styles.typographyMessageHint}>
                {t("modal.nebulize.stop.description")}
              </p>
            )
          }
          actions={{
            onClick: closeDiffuserModal,
            type: "danger",
            text: t("modal.button.close"),
          }}
          isOpen={!!showDiffuseModal && showDiffuseModal !== "hide"}
        /> */}
        <Modal
          title={t("stop-test-title")}
          children={
            <>
              <p className={styles.typographyMessage}>
                {t("stop-test-message")}
              </p>
              <p className={styles.typographyMessage}>
                {t("stop-test-description")}
              </p>
            </>
          }
          actions={[
            {
              onClick: toggleStopModal,
              type: "default",
              text: t("modal.button.cancelStop"),
            },
            {
              onClick: stopRapidTest,
              type: "danger",
              text: t("modal.button.confirmStop"),
            },
          ]}
          isOpen={showStopModal}
        />
        <Modal
          title={
            rapidTest.errorcode === 112
              ? t("modal.error.bgTimeout")
              : rapidTest.errorcode === 113
              ? t("modal.error.nebulizationTimeoutTitle")
              : "Error"
          }
          children={
            rapidTest.errorcode === 112 ? (
              <>
                <p className={styles.typographyMessage}>
                  {t("modal.error.bgTooLong")}
                </p>
                <p className={styles.typographyMessage}>
                  {t("modal.error.restartTest")}
                </p>
              </>
            ) : rapidTest.errorcode === 113 ? (
              <>
                <p className={styles.typographyMessage}>
                  {t("modal.error.nebulizationTimeout")}
                </p>
                <p className={styles.typographyMessage}>
                  {t("modal.error.restartTest")}
                </p>
                <p className={styles.typographyMessageHint}>
                  {t("modal.error.nebulizationHint")}
                </p>
              </>
            ) : (
              <></>
            )
          }
          actions={errorModalActions}
          isOpen={rapidTest.status === "error" && rapidTest.errorcode !== 500}
        />
        <Modal
          title={t("error.500.title")}
          children={
            <>
              <p className={styles.typographyMessage}>{t("error.500.body")}</p>
            </>
          }
          actions={[
            {
              onClick: toggleMacsoErrorOpen,
              type: "default",
              text: t("modal.button.close"),
            },
          ]}
          isOpen={macsoErrorOpen}
        />
      </>
    </div>
  );
};

export default RapidTest;
